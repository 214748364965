import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {  
  const data = useStaticQuery(graphql`
    {
      logoScratch1: file(relativePath: {eq: "scratch1-long.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      duncanPortrait: file(relativePath: {eq: "Ducan-pollard-portrait-square.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      footerLinkedin: file(relativePath: {eq: "linkedin-logo.png"}) {
        childImageSharp {
          fixed (width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      footerPildacre: file(relativePath: {eq: "pildacre-logo.png"}) {
        childImageSharp {
          fixed (width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
  <Layout backgroundColor="rgb(251,251,253)">
    <SEO title="Duncan Pollard &amp; Associates  - A consultancy for business, civil society and academia" />

    <div class="header-image">
    <Img fluid={data.logoScratch1.childImageSharp.fluid} alt="Sketch 1" />
    </div>
    {/* --- About Block  */}

    <div className="content-center p-5 md:p-20 bg-blue text-left md:text-justify text-white">
      <div className="mx-auto max-w-screen-xl">

        <h2>About the Founder</h2> 
        <div class="flex flex-wrap -mx-2">
          <div class="w-full md:w-1/2 lg:w-1/3 px-2"> 
            <p>Duncan’s career has seen him move between the corporate and non-profit sectors. He has an honours degree in forestry and first worked for a variety of forest companies; including spending nine years with Shell, firstly in their London head office, then in Chile and Uruguay. He worked in planning and operational roles setting up world scale greenfield forest and pulpmill projects.</p>
          
            <p>He then spent almost ten years with WWF International, in the Forests for Life Programme and then as the Director responsible for WWF’s global conservation work on energy, water, forests, species, oceans and policy. Duncan helped build WWF’s capability to work with business, involving both collaborative partnerships and campaigning approaches.</p>

            <p>In 2010 Duncan joined Nestlé, where he worked to embed sustainability across the different functions, businesses and markets. </p>
            </div>
            
          <div className="w-full md:w-1/2 lg:w-1/3 px-2">
          <p>He led and supported the development of programmes on responsible sourcing, rural development & livelihoods, climate, biodiversity, plastic packaging, food systems, and new ways of measuring societal value creation.</p>
          <p>An important aspect of the role was to develop and enhance relationships with governments and stakeholders (NGO, investors, and corporate platforms). Collaborating with stakeholders became part of Nestlé’s operational strategy.</p>

          <p>Duncan set up Duncan Pollard & Associates to help companies, civil society, communities and academia in the areas equality and sustainability. Through a combination of paid consultancy and pro-bono work, it helps them to make a difference.</p>
          
          </div>
          
          <div className="w-full md:w-1/2 lg:w-1/3 px-2">
            <Img fluid={data.duncanPortrait.childImageSharp.fluid} alt="Duncan Pollard Portrait" />
          </div>
        </div>
      </div>
    </div>

    {/* --- About Block  */}



    <div className="p-5 md:m-20">
      <div className="mx-auto max-w-screen-xl">

      <div class="max-w-lg mx-auto text-center">
        <h2>Experience</h2>
        <p>With over 30 years of experience on the ground, at the board, and in the corridors. I can help navigate the complexity and inter-connectness of today's sustainability system challenges: in three areas:</p>
      </div>




    


    <h3>Sustainability</h3>

      <div class="flex">
        <div class="w-1/5 md:w-1/3  text-center">
          <div class="h-24 py-8 bg-gray-200">
            <h4 className="text-xs md:text-base">Business Success</h4>
          </div>  
        </div>
        <div class="w-3/5 md:w-1/3 text-center">
          <div class="h-24 bg-jigsaw py-8 bg-blue text-white bg-cover">
            <h4 className="text-xs md:text-base">Integrate</h4>
          </div>  
        </div>
        <div class="w-1/5 md:w-1/3 text-center">
          <div class="h-24 py-8 bg-gray-200">
            <h4 className="text-xs md:text-base">Societal progress</h4>
          </div>  
        </div>
       </div> 

      <div class="flex">
        <div class="sm:w-full md:w-2/3 lg:w-1/3 m-auto px-5 py-2 text-sm text-center">Embedding and integrating sustainability across a company:  functions, business units & markets.</div>
      </div>


      {/* Start of Engagement */}
      <h3>Engagement</h3>

      <div class="flex">
        <div class="w-1/5 md:w-1/3 text-center">
          <div class="h-24 py-8 bg-gray-200">
            <h4 className="text-xs md:text-base">Companies</h4>
          </div>  
        </div>
        <div class="w-3/5 md:w-1/3 text-center">
          <div class="h-24 bg-jigsaw py-8 bg-blue text-white bg-cover">
            <h4 className="text-xs md:text-base">Intermediary</h4>
          </div>  
        </div>
        <div class="w-1/5 md:w-1/3 text-center">
          <div class="h-24 py-8 bg-gray-200">
            <h4 className="text-xs md:text-base">Civil society</h4>
          </div>  
        </div>
       </div> 

      <div class="flex">
        <div class="sm:w-full md:w-2/3 lg:w-1/3 m-auto px-5 py-2 text-sm text-center">Acting as an Intermediary between companies and civil society to interpret each other and work effectively together.</div>
      </div>
      {/* End of Engagement */}



      {/* Start of Impact */}
      <h3>Impact</h3>
 
         <div class="flex">
        <div class="w-1/5 md:w-1/3 text-center">
          <div class="h-24 py-8 bg-gray-200">
            <h4 className="text-xs md:text-base">Strategy</h4>
          </div>  
        </div>
        <div class="w-3/5 md:w-1/3 text-center">
          <div class="h-24 bg-jigsaw py-8 bg-blue text-white bg-cover">
            <h4 className="text-xs md:text-base">Operationalise</h4>
          </div>  
        </div>
        <div class="w-1/5 md:w-1/3 text-center">
          <div class="h-24 py-8 bg-gray-200">
            <h4 className="text-xs md:text-base">Impact</h4>
          </div>  
        </div>
       </div> 

      <div class="flex">
        <div class="sm:w-full md:w-2/3 lg:w-1/3  m-auto px-5 py-2 text-sm text-center">Operationalise is the step between strategy and execution. It focusses upon outcomes and impact, rather than actions.</div>
      </div>
      {/* End of Impact */}

      </div>
    </div>


    <div className="mx-auto max-w-screen-xl">

    <div className="text-center">
      <h2>Contacts & More</h2>
    </div>
      <div className="flex flex-wrap px-10 pt-10 md:mx-20 md:mt-20 ">
        <div className="md:w-1/2">
          <p>
          Email<br />
          <a href="mailto:duncan@dpollardassociates.eco" className="no-underline hover:underline">duncan@dpollardassociates.eco</a></p>
        </div>
        <div class="md:w-1/2 text-right">
          Find more about Duncan
          <br/>
          <div className="flex flex-row-reverse -mx-2 text-center">
          <div className="mx-2"><a href="https://www.linkedin.com/in/duncan-pollard-643880b/"><Img fixed={data.footerLinkedin.childImageSharp.fixed} alt="Logo Linkedin Profile" /><br/>Linkedin</a></div>
            <div className="mx-2">
            <a href="https://pildacrehill.net/about/">
            <Img fixed={data.footerPildacre.childImageSharp.fixed} alt="Logo Pildacre Blog" /><br/>Pildacre Hill
            </a></div>

          </div>
        </div>
      </div>
    </div>





   


  </Layout>
  </>
  )
}




export default IndexPage